import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Constant } from 'src/app/core/constant';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { environment } from 'src/environments/environment';
declare var $: any;
declare var Stripe: any;
@Component({
  selector: 'app-getstart',
  templateUrl: './getstart.component.html',
  styleUrls: ['./getstart.component.scss']
})
export class GetstartComponent implements OnInit {
  slides = [
    { img: "assets/img/quotes.png", para: "Violations are always time consuming process for our neighborhood. Our account executive made the setup process easy and we were up and running in a short time. Now we are able to handle any violation in a matter of minutes. I feel this is something that a lot of neighborhoods will be using soon. ", name: "Rich S- Vill Lago Sandestin" },
    { img: "assets/img/quotes.png", para: "Violations are always time consuming process for our neighborhood. Our account executive made the setup process easy and we were up and running in a short time. Now we are able to handle any violation in a matter of minutes. I feel this is something that a lot of neighborhoods will be using soon. ", name: "Rich S- Vill Lago Sandestin" },
    { img: "assets/img/quotes.png", para: "Violations are always time consuming process for our neighborhood. Our account executive made the setup process easy and we were up and running in a short time. Now we are able to handle any violation in a matter of minutes. I feel this is something that a lot of neighborhoods will be using soon. ", name: "Rich S- Vill Lago Sandestin" },
    // { img: "assets/img/quotes.png", para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.", name: "Navana Gomes", position: "Chief Accountant" },
    // { img: "assets/img/quotes.png", para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.", name: "Navana Gomes", position: "Chief Accountant" },
    // { img: "assets/img/quotes.png", para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.", name: "Navana Gomes", position: "Chief Accountant" }
  ];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 3,
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  };
  packageData: any = [];
  currentPage: any = 1;
  previousBtnEnabled: boolean = false;
  nextBtnEnabled: boolean = false;
  totalPages: any = 1;
  public model = {
    CONTACT_SUPPORT: '',
    LEGAL: '',
    PRIVACY_POLICY: '',
    FAQS: '',
    OUR_MISSION: '',
    EASY_TO_USE: '',
    MEMBERSHIP: '',
    FINCO_APP: '',
    WHERE_DOES_IT_COME_FROM: '',
    GET_START: '',
    OUR_PRICING_PLANS: ''
  };
  propertyForm: FormGroup;
  submitted: boolean = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  isNameValid: boolean = true;

  constructor(private router: Router,
    private https: HttpsService,
    public constant: Constant,
    private fb: FormBuilder,
    private commonService: CommonService) { }

  ngOnInit() {
    this.propertyForm = this.fb.group({
      ownerName: ['', Validators.required],
      userName: ['', Validators.required],
      ownerEmail: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      ownerPhoneNo: ['', [Validators.required, Validators.minLength(7)]],
      ownerStreetAdd: ['', Validators.required],
      ownerCity: ['', Validators.required],
      ownerState: ['', Validators.required],
      ownerZipCode: ['', [Validators.required, Validators.minLength(4)]],
      societyName: ['', Validators.required],
      societyCity: ['', Validators.required],
      societyState: ['', Validators.required],
      societyZipCode: ['', [Validators.required, Validators.minLength(4)]],
    });
    this.getPackages(1);
    this.getAllCms();
    if (this.router.url.search('top') !== -1) {
      document.getElementById('top').scrollIntoView();
    }
    this.propertyForm.get('userName').valueChanges.subscribe(data => {
      // this.checkUserName(data);
    })
  }

  getPackages(pageNo) {
    var param = {
      // "pageNo": pageNo,
      // "limit": 10
      //this
    }
    this.https.httpPostWithHeader('getPackages', param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.packageData = res.data.packages.sort((a, b) => a.order - b.order);
        this.totalPages = res.data.totalPages;
      }
    });
  }

  getAllCms() {
    this.https.httpPost('getAllCms', {}).subscribe((res: any) => {
      if (res.statusCode == 200) {
        res.data.forEach(element => {
          if (element.cmsType === this.constant.CMS.OUR_MISSION) {
            this.model.OUR_MISSION = element.html;
          }
          if (element.cmsType === this.constant.CMS.EASY_TO_USE) {
            this.model.EASY_TO_USE = element.html;
          }
          if (element.cmsType === this.constant.CMS.MEMBERSHIP) {
            this.model.MEMBERSHIP = element.html;
          }
          if (element.cmsType === this.constant.CMS.FINCO_APP) {
            this.model.FINCO_APP = element.html;
          }
          if (element.cmsType === this.constant.CMS.WHERE_DOES_IT_COME_FROM) {
            this.model.WHERE_DOES_IT_COME_FROM = element.html;
          }
          if (element.cmsType === this.constant.CMS.GET_START) {
            this.model.GET_START = element.html;
          }
          if (element.cmsType === this.constant.CMS.OUR_PRICING_PLANS) {
            this.model.OUR_PRICING_PLANS = element.html;
          }
        })
      }
    })
  }

  selectPackage(data) {
    this.submitted = true;
    // if(localStorage.societyId !== undefined) {
    //   this.router.navigateByUrl('/packagedetails/' + data._id);
    // } else {
    if (this.propertyForm.valid) {
      if (this.propertyForm.valid && this.propertyForm.value.ownerZipCode.trim().length >= 4 && this.propertyForm.value.societyZipCode.trim().length >= 4) {
        this.submit().then((societyId: any) => {
          if (societyId) {
            // this.router.navigateByUrl('/packagedetails/' + data._id);
            this.goToPaymentPage(data);
          }
        });
      } else if (this.propertyForm.value.ownerZipCode.trim().length < 4) {
        document.getElementById('top').scrollIntoView();
        this.propertyForm.get('ownerZipCode').patchValue(this.propertyForm.value.ownerZipCode.trim());
      } else if (this.propertyForm.value.societyZipCode.trim().length < 4) {
        document.getElementById('top').scrollIntoView();
        this.propertyForm.get('societyZipCode').patchValue(this.propertyForm.value.societyZipCode.trim());
      }
    } else {
      document.getElementById('top').scrollIntoView();
    }
    // }
  }

  async goToPaymentPage(pckg) {
    if (localStorage.societyId !== undefined) {
      var httpParam = new URLSearchParams();
      var societyId = localStorage.getItem('societyId');
      httpParam.set('societyId', societyId);
      httpParam.set('amount', pckg.price);
      httpParam.set('packageId', pckg._id);
      // var stripe = Stripe(environment.publishableKey);
      // this.createCheckoutSession(pckg.price).then((resuly: any) => {
      //   console.log(resuly)
      // const session = await stripe.checkout.sessions.create({
      //   success_url: 'https://example.com/success',
      //   cancel_url: 'https://example.com/cancel',
      //   payment_method_types: ['card'],
      //   line_items: [
      //     { price: pckg.price.toString(), quantity: 1 },
      //   ],
      //   mode: 'payment',
      // });
      // console.log(session);
      // stripe.redirectToCheckout({ sessionId: session.id }).then((response: any) => {
      //   console.log(response);
      // })
      // })
      // stripe.createToken()

      this.router.navigateByUrl('/payment/'+societyId+'/'+pckg._id+'/'+pckg.price)
      // this.https.httpGetWithQParam('getPaymentPage', httpParam).subscribe((res: any) => {
      //   if (res.statusCode == 200) {
      //     window.open(res.data.redirectUrl, "_self");
      //     localStorage.setItem('payment', 'true');
      //   }
      // });
    } else {
      // this.router.navigateByUrl('/getstart');
      // this.commonService.presentsToast('error', 'top-end', 'Please upload your files first.');
    }
  }

  createCheckoutSession(priceId) {
    return fetch("/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        priceId: priceId
      })
    }).then((res: any) => { this.handleFetchResult(res) });
  };

  handleFetchResult(result) {
    if (!result.ok) {
      return result.json().then(function (json) {
        if (json.error && json.error.message) {
          throw new Error(result.url + ' ' + result.status + ' ' + json.error.message);
        }
      }).catch(function (err) {
        throw err;
      });
    }
    return result.json();
  };

  submit() {
    this.submitted = true;
    return new Promise((resolve, reject) => {
      var param = $.extend({}, {
        "societyName": this.propertyForm.value.societyName.trim(),
        "societyCity": this.propertyForm.value.societyCity.trim(),
        "societyState": this.propertyForm.value.societyState.trim(),
        "societyZipcode": this.propertyForm.value.societyZipCode.trim(),
        "name": this.propertyForm.value.ownerName.trim(),
        "address": this.propertyForm.value.ownerStreetAdd.trim(),
        "zipCode": this.propertyForm.value.ownerZipCode.trim(),
        "city": this.propertyForm.value.ownerCity.trim(),
        "state": this.propertyForm.value.ownerState.trim(),
        "countryCode": "+1",
        "email": this.propertyForm.value.ownerEmail.trim(),
        "phoneNo": this.propertyForm.value.ownerPhoneNo.trim(),
        "deviceType": this.constant.DEVICE_TYPE.WEB,
        "userName":this.propertyForm.value.userName.trim(),
      });
      this.https.httpPost('addSociety', param).subscribe((res: any) => {
        if (res.statusCode == 203) {
          this.commonService.presentsToast('success', 'top-end', res.message);
          this.submitted = false;
          this.propertyForm.reset();
          localStorage.setItem('societyId', res.data._id);
          return resolve(res.data._id);
        } else if (res.statusCode == 200) {
          this.commonService.presentsToast('error', 'top-end', res.message);
        }
      });
    })

  }

  onkeydown(event) {
    if (event.charCode !== 32) {
      return (event.charCode > 64 &&
        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)
    }
  }

  keypresshandler(event) {
    var charCode = event.keyCode;
    //Non-numeric character range
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
  }

  checkUserName(name) {
    this.https.httpPostWithHeader('checkUserName', {userName: name}).subscribe((response: any) => {
      this.isNameValid = response.data.isValid;
    });
  }

  //pagination
  onNextPage() {
    this.currentPage++;
    this.updateTable(this.currentPage);

  }
  onChnagePage(page) {
    this.currentPage = page;
    this.updateTable(page);
  }

  onPrevPage() {
    this.currentPage--;
    this.updateTable(this.currentPage);
  }

  updateTable(currentPage) {
    this.previousBtnEnabled = currentPage > 1;
    this.nextBtnEnabled = currentPage < this.totalPages;
    this.getPackages(currentPage);
  }
}
