import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GetstartComponent } from "./pages/getstart/getstart.component";
import { HomeComponent } from "./pages/home/home.component";
import { InstructionComponent } from "./pages/instruction/instruction.component";
import { PackagedetailsComponent } from "./pages/packagedetails/packagedetails.component";
import { PaymentComponent } from "./pages/payment/payment.component";
import { SignupComponent } from "./pages/signup/signup.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "instruction",
    component: InstructionComponent,
  },
  {
    path: "getstart",
    component: GetstartComponent,
  },
  {
    path: "packagedetails/:id",
    component: PackagedetailsComponent,
  },
  {
    path: "packagedetails/:id/:societyId",
    component: PackagedetailsComponent,
  },
  {
    path: "signup",
    component: SignupComponent,
  },
  {
    path: "privacy",
    component: PrivacyComponent,
  },
  {
    path: "payment/:societyId/:packageId/:price",
    component: PaymentComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
