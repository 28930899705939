import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constant } from 'src/app/core/constant';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  slides = [
    { img: "assets/img/quotes.png", para: "Violations are always time consuming process for our neighborhood. Our account executive made the setup process easy and we were up and running in a short time. Now we are able to handle any violation in a matter of minutes. I feel this is something that a lot of neighborhoods will be using soon. ", name: "Rich S- Vill Lago Sandestin"},
    // { img: "assets/img/quotes.png", para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.", name: "Navana Gomes", position: "Chief Accountant" },
    // { img: "assets/img/quotes.png", para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.", name: "Navana Gomes", position: "Chief Accountant" },
    // { img: "assets/img/quotes.png", para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.", name: "Navana Gomes", position: "Chief Accountant" }
  ];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 3,
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  };
  public model = {
    CONTACT_SUPPORT: '',
    LEGAL: '',
    PRIVACY_POLICY: '',
    FAQS: '',
    OUR_MISSION: '',
    EASY_TO_USE: '',
    MEMBERSHIP: '',
    FINCO_APP: '',
    WHERE_DOES_IT_COME_FROM: '',
    GET_START: '',
    OUR_PRICING_PLANS: ''
  };

  constructor(private https: HttpsService,
    public constant: Constant,
    private commonServcie: CommonService,
    private router: Router) { }

  ngOnInit() {
    if(this.router.url.search('top') !== -1) {
      document.getElementById('top').scrollIntoView();
    }
    this.getAllCms()
    if(localStorage.payment !== undefined) {
      if(localStorage.payment === 'true' && localStorage.societyId !== undefined) {
        this.https.httpPost('societyPaymentStatus',{societyId: localStorage.societyId}).subscribe((res: any) => {
          if(res.statusCode == 200) {
            if(res.data.isPaymentDone) {
              localStorage.clear();
            }
          }
        });
      }
    }
  }

  getAllCms() {
    this.https.httpPost('getAllCms',{}).subscribe((res: any) => {
      if(res.statusCode == 200) {
        res.data.forEach(element => {
        if(element.cmsType === this.constant.CMS.OUR_MISSION) {
          this.model.OUR_MISSION = element.html;
        }
        if(element.cmsType === this.constant.CMS.EASY_TO_USE) {
          this.model.EASY_TO_USE = element.html;
        }
        if(element.cmsType === this.constant.CMS.MEMBERSHIP) {
          this.model.MEMBERSHIP = element.html;
        }
        if(element.cmsType === this.constant.CMS.FINCO_APP) {
          this.model.FINCO_APP = element.html;
        }
        if(element.cmsType === this.constant.CMS.WHERE_DOES_IT_COME_FROM) {
          this.model.WHERE_DOES_IT_COME_FROM = element.html;
        }
        if(element.cmsType === this.constant.CMS.GET_START) {
          this.model.GET_START = element.html;
        }
        if(element.cmsType === this.constant.CMS.OUR_PRICING_PLANS) {
          this.model.OUR_PRICING_PLANS = element.html;
        }
      })
      }
    })
  }
 
  goToPlayStore() {
    window.open("https://play.google.com/store", "_blank");
  }

  goToInstruction() {
    this.router.navigateByUrl('/instruction');
    localStorage.scroll = true;
    // this.commonServcie.instructionScroll.next(true);
  }

  goToSignup() {
    this.router.navigateByUrl('/getstart#top');
  }

}
