import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { Constant } from 'src/app/core/constant';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
declare var $: any;
import Swal from 'sweetalert2';
import { apiList } from '../../services/http/api-list';
@Component({
  selector: 'app-packagedetails',
  templateUrl: './packagedetails.component.html',
  styleUrls: ['./packagedetails.component.scss']
})
export class PackagedetailsComponent implements OnInit {
  packageId: string;
  packageData: any;
  image: FormControl = new FormControl(null);
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  propertyForm: FormGroup;
  submitted: boolean = false;
  fileData: any = '';
  fileDataUrl: string = '';
  imgBaseUrl = environment.imageUrl;
  societyId;
  baseUrl: string = environment.backendUrl;
  isFilesUploaded: boolean = false;

  constructor(private route: ActivatedRoute,
    private https: HttpsService,
    private http: HttpClient,
    private router: Router,
    private fb: FormBuilder,
    private commonService: CommonService,
    private constant: Constant,
    private apiList: apiList) {
    this.packageId = this.route.snapshot.paramMap.get('id');
    let societyId = this.route.snapshot.paramMap.get('societyId');
    societyId && (this.societyId = societyId);
    !societyId && (this.societyId = localStorage.societyId);
  }

  ngOnInit() {
    this.getPackageDetails();
    this.propertyForm = this.fb.group({
      fName: ['', Validators.required],
      lName: ['', Validators.required],
      name: ['', Validators.required],
      email: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phoneNo: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.minLength(4)]],
      city: ['', Validators.required],
      state: ['', Validators.required],
    });
  }

  getPackageDetails() {
    this.https.httpPostWithHeader('getPackage', { _id: this.packageId }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.packageData = res.data;
      }
    })
  }

  goToPaymentPage() {
    if (localStorage.societyId !== undefined && this.isFilesUploaded) {
      var httpParam = new URLSearchParams();
      httpParam.set('societyId', localStorage.getItem('societyId'));
      httpParam.set('amount', this.packageData.price);
      httpParam.set('packageId', this.packageId);
      this.https.httpGetWithQParam('getPaymentPage', httpParam).subscribe((res: any) => {
        if (res.statusCode == 200) {
          window.open(res.data.redirectUrl, "_self");
          localStorage.setItem('payment', 'true');
        }
      });
    } else {
      // this.router.navigateByUrl('/getstart');
      this.commonService.presentsToast('error', 'top-end', 'Please upload your files first.');
    }
  }

  onFileChange(fileInput: any) {
    console.log(fileInput.target.files);
    if (fileInput.target.files && fileInput.target.files[0]) {
      var formData = new FormData();
      for (let i = 0; i < fileInput.target.files.length; i++) {
        formData.append('uploadFiles', fileInput.target.files[i]);
      }
      formData.append('societyId', this.societyId);
      const headers = new HttpHeaders({
        "mimeType": "multipart/form-data",
      });
      const headersObj = { headers };
      this.http.post(this.baseUrl + this.apiList['uploadFiles'], formData, headersObj).subscribe((res: any) => {
        if (res.status == 200) {
          if (res.result.length > 0) {
            this.isFilesUploaded = true;
            Swal.fire(
              'Good job!',
              'Your files has been uploaded successfully!',
              'success'
            )
            // this.commonService.presentsToast('top-end', 'success', 'Your files has been uploaded successfully.')
            // this.goToPaymentPage();
            this.router.navigateByUrl('/home');
          }
        }
      });
    }

  }

  dowloadPdfFile() {
    var link = 'https://api.fincogrp.com/api/v1/website/getFiles';
    window.open(link, '_blank');
  }


  onkeydown(event) {
    if (event.charCode !== 32) {
      return (event.charCode > 64 &&
        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)
    }
  }

  submit() {
    this.submitted = true;
    if (this.propertyForm.valid) {
      var codeLength = this.propertyForm.value.phoneNo.dialCode.length;
      var param = $.extend({}, {
        "firstName": this.propertyForm.value.fName.trim(),
        "lastName": this.propertyForm.value.lName.trim(),
        "state": this.propertyForm.value.state.trim(),
        "city": this.propertyForm.value.city.trim(),
        "zipCode": this.propertyForm.value.zipCode.trim(),
        "societyName": this.propertyForm.value.name.trim(),
        "email": this.propertyForm.value.email.trim(),
        "phoneNo": this.propertyForm.value.phoneNo.e164Number.slice(codeLength),
        "countryCode": this.propertyForm.value.phoneNo.dialCode,
        "deviceType": this.constant.DEVICE_TYPE.WEB,
      });
      this.https.httpPost('addSociety', param).subscribe((res: any) => {
        if (res.statusCode == 203) {
          this.commonService.presentsToast('success', 'top-end', res.message);
          this.submitted = false;
          this.propertyForm.reset();
          localStorage.setItem('societyId', res.data._id);
        } else if (res.statusCode == 200) {
          this.commonService.presentsToast('error', 'top-end', res.message);
        }
      });
    }
  }

  goToSignupForm() {
    // if (localStorage.societyId !== undefined) {
    //   document.getElementById('fileUpload').click();
    // } else {
    this.commonService.presentsToast('error', 'top-end', 'Please fill out detail form.');
    this.router.navigateByUrl('/getstart');
    // }
  }
}

export class User {
  id: number;
  name: String;
  lastName: String;

  constructor(id: number, name: String, lastName: String) {
    this.id = id;
    this.name = name;
    this.lastName = lastName;
  }
}
