import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { getValidationConfigFromCardNo } from 'src/app/core/helpers/card.helper';
import { luhnValidator } from 'src/app/core/validators/luhnValidator';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
declare var Stripe: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  societyId: string;
  packageId: string;
  price: string;
  cardNumberGroup: FormGroup;
  expiryYears: any = [];
  cardSubmitted = false;
  cardForm: FormGroup;
  firstName = new FormControl('', [Validators.required]);
  lastName = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required]);
  state = new FormControl('', [Validators.required]);
  zipCode = new FormControl('', [Validators.required]);
  expiryMonth = new FormControl('', [Validators.required]);
  expiryYear = new FormControl('', [Validators.required]);
  cvv = new FormControl('', [Validators.required]);
  card: any;
  stripe: any;
  cardHolderName: FormControl = new FormControl('', [Validators.required]);

  constructor(private route: ActivatedRoute, private https: HttpsService,
    private commonService: CommonService, private router: Router,
    private location: LocationStrategy, private ngxService: NgxUiLoaderService) {
    this.societyId = this.route.snapshot.paramMap.get('societyId');
    this.packageId = this.route.snapshot.paramMap.get('packageId');
    this.price = this.route.snapshot.paramMap.get('price');
    this.location.onPopState(() => {
      return false;
    });

  }

  ngOnInit() {
    this.cardNumberGroup = new FormGroup({
      cardNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(12),
        luhnValidator()
      ]),
    });
    this.cardHolderName.valueChanges.subscribe((res: any) => {
      console.log(res);
    })

    var currentYear = (new Date()).getFullYear();
    for (var i = currentYear; i <= currentYear + 15; i++) {
      this.expiryYears.push(i.toString().slice(2));
    }
    this.stripe = Stripe('pk_test_51Gql2KDlZSBv0NOipqGR9KP1cKGdan0NQ0mmatx8Awl42yYTFVOROJnl7YDg1sIG0SFg0JXtpBtYAH9jtSvypxpZ007PpcQwCs');
    var elements = this.stripe.elements();
    this.card = elements.create('cardNumber', {
      classes: {
        base: "form-control",
        invalid: "error"
      }
    });
    var cvc = elements.create('cardCvc', {
      classes: {
        base: "form-control",
        type: 'password',
        invalid: "error"
      },
      type: 'password'
    });
    cvc.type = 'password';
    console.log(cvc);
    // document.getElementById('card-cvc').type = 'password'
    var exp = elements.create('cardExpiry', {
      classes: {
        base: "form-control",
        invalid: "error"
      }
    });
    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount('#card-number');
    cvc.mount('#card-cvc');
    exp.mount('#card-exp');
    this.preventBackButton();
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      return false;
    });
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  getCardNumberControl(): AbstractControl | null {
    return this.cardNumberGroup && this.cardNumberGroup.get('cardNumber');
  }

  cardMaskFunction(rawValue: string) {
    const card = getValidationConfigFromCardNo(rawValue);
    console.log(card);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }

  submit() {
    this.cardSubmitted = true;
    if(this.cardHolderName.valid && this.cardHolderName.value.trim() !== '') {
      this.ngxService.start();
      return new Promise((resolve, reject) => {
        this.stripe.createToken(this.card).then((result) => {
          if (result.error) {
            this.ngxService.stop()
            // Inform the customer that there was an error.
            var errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
          } else {
            // Send the token to your server.
            console.log(result.token)
            this.ngxService.stop()
            this.stripeTokenHandler(result.token);
            return resolve(result.token);
          }
        });
  
      })
    }
  }

  stripeTokenHandler(token) {
    // Insert the token ID into the form so it gets submitted to the server
    var param = {
      "firstName": this.cardHolderName.value.trim(),
      "amount": this.price,
      "currency": "USD",
      "token": token
    }
    this.ngxService.start();
    this.https.httpPostWithHeader('payment', param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.ngxService.stop();
        this.commonService.presentsToast('success', 'top-end', 'Your payment has been completed successfully.')
        let societyId;
        localStorage.societyId !== undefined && (societyId = localStorage.societyId);
        societyId && this.router.navigateByUrl('/packagedetails/' + this.packageId+'/'+ societyId);
        !societyId && this.router.navigateByUrl('/packagedetails/' + this.packageId);
      }
    })
  }

}
