
export class apiList {
    getPackages: string = '/getPackages';
    addCollaboration: string = '/addCollaboration';
    getPackage: string = '/getPackage';
    addSociety: string = '/addSociety';
    upload: string = '/upload';
    getPaymentPage: string = '/getPaymentPage';
    societyPaymentStatus: string = '/societyPaymentStatus';
    getAllCms: string = '/getAllCms';
    uploadFile: string = '/uploadFile';
    getFiles: string = '/getFiles';
    uploadFiles: string = '/uploadFiles';
    payment: string = '/payment';
    checkUserName: string = '/checkUserName';
}