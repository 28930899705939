import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Constant } from 'src/app/core/constant';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss']
})
export class InstructionComponent implements OnInit {
  
  slides = [
    { img: "assets/img/finco_1_main.png", name: "Sign In / Sign Up", para: "Sign in to the app or sign up on the website with all your Neighborhood information." },
    { img: "assets/img/finco_2_login.png", name: "Sign In", para: "When you sign in all of your Neighborhood  information will be automatically loaded." },
    { img: "assets/img/finco_3_select.png", name: "Select Violation", para: "Select Record Violation to begin the fining process." },
    { img: "assets/img/finco_4_name.png", name: "Violation Location", para: "Select the address, select the type of violation, select warning of fine, see the property violation history." },
    { img: "assets/img/finco_5_information.png", name: "Property Information", para: "Homeowner information is displayed. Take photo or video to record violation." },
    { img: "assets/img/finco_6_photo.png", name: "Take Photo/Video", para: "Take photo or video of the violation and home. All information about the violation will be displayed on the photos/videos." },
    { img: "assets/img/finco_7_select.png", name: "Select Photo/Video", para: "Select the photos the pertain to the violation and the parties that will receive the email and hit send." }
  ];
  slideConfig = {
    "slidesToShow": 1, "slidesToScroll": 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    
    dots: true,
  };

  slidesSecond = [
    { img: "assets/img/quotes.png", para: "Violations are always time consuming process for our neighborhood. Our account executive made the setup process easy and we were up and running in a short time. Now we are able to handle any violation in a matter of minutes. I feel this is something that a lot of neighborhoods will be using soon. ", name: "Rich S- Vill Lago Sandestin"},
    { img: "assets/img/quotes.png", para: "Violations are always time consuming process for our neighborhood. Our account executive made the setup process easy and we were up and running in a short time. Now we are able to handle any violation in a matter of minutes. I feel this is something that a lot of neighborhoods will be using soon.", name: "Navana Gomes", position: "Chief Accountant" },
    { img: "assets/img/quotes.png", para: "Violations are always time consuming process for our neighborhood. Our account executive made the setup process easy and we were up and running in a short time. Now we are able to handle any violation in a matter of minutes. I feel this is something that a lot of neighborhoods will be using soon.", name: "Navana Gomes", position: "Chief Accountant" },
    { img: "assets/img/quotes.png", para: "Violations are always time consuming process for our neighborhood. Our account executive made the setup process easy and we were up and running in a short time. Now we are able to handle any violation in a matter of minutes. I feel this is something that a lot of neighborhoods will be using soon.", name: "Navana Gomes", position: "Chief Accountant" }
  ];
  slideConfigSecond = {
    slidesToShow: 1,
    slidesToScroll: 3,
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  };

  public model = {
    CONTACT_SUPPORT: '',
    LEGAL: '',
    PRIVACY_POLICY: '',
    FAQS: '',
    OUR_MISSION: '',
    EASY_TO_USE: '',
    MEMBERSHIP: '',
    FINCO_APP: '',
    WHERE_DOES_IT_COME_FROM: '',
    GET_START: '',
    OUR_PRICING_PLANS: ''
  };
  constructor(private https: HttpsService,
    public constant: Constant,
    private router: Router,
    private commonServcie: CommonService) { }

  ngOnInit() {
    if(this.router.url.search('top') !== -1) {
      document.getElementById('top').scrollIntoView();
    }
    if (localStorage.scroll !== undefined) {
      document.getElementById('top').scrollIntoView();
      localStorage.removeItem('scroll');
    }
    window.scrollTo(0, 0);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.getAllCms();
  }
  goTomore() {
    window.open("http://appgrowthcompany.com/fincoadmin/#/login", "_blank");
  }
  getAllCms() {
    this.https.httpPost('getAllCms', {}).subscribe((res: any) => {
      if (res.statusCode == 200) {
        res.data.forEach(element => {
          if (element.cmsType === this.constant.CMS.OUR_MISSION) {
            this.model.OUR_MISSION = element.html;
          }
          if (element.cmsType === this.constant.CMS.EASY_TO_USE) {
            this.model.EASY_TO_USE = element.html;
          }
          if (element.cmsType === this.constant.CMS.MEMBERSHIP) {
            this.model.MEMBERSHIP = element.html;
          }
          if (element.cmsType === this.constant.CMS.FINCO_APP) {
            this.model.FINCO_APP = element.html;
          }
          if (element.cmsType === this.constant.CMS.WHERE_DOES_IT_COME_FROM) {
            this.model.WHERE_DOES_IT_COME_FROM = element.html;
          }
          if (element.cmsType === this.constant.CMS.GET_START) {
            this.model.GET_START = element.html;
          }
          if (element.cmsType === this.constant.CMS.OUR_PRICING_PLANS) {
            this.model.OUR_PRICING_PLANS = element.html;
          }
        })
      }
    })
  }
}
