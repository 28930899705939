import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
// import { request } from 'http';
import { catchError, filter, take, switchMap, finalize, retry, tap } from "rxjs/operators";
import { CommonService } from '../common/common.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  private token: string;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private commonbService: CommonService,
    private router: Router,
    private ngxService: NgxUiLoaderService) {
      this.ngxService.start();
    if(localStorage.accessToken != undefined || localStorage.accessToken != null) {
      this.token = localStorage.accessToken
    }
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // if (!req.headers.has('Content-Type')) {
    //   req = req.clone({
    //     headers: req.headers.set('Content-Type', 'application/json')
    //   });
    // }
    
    return next.handle(req).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          // 401 handled in auth.interceptor
          // this.commonbService.presentsToast('error','top-end','you are logout for security purpose.');
        }
        return throwError(error);
      }),
      finalize(() => { this.ngxService.stop(); }),
      tap(event => {
        var eventRes = JSON.parse(JSON.stringify(event));
        if(eventRes.body) {
          if(eventRes.body.statusCode === 400) {
            if(eventRes.body.error) {
              this.commonbService.presentsToast('error','top-end',eventRes.body.error);
            } else if(eventRes.body.message) {
              this.commonbService.presentsToast('error','top-end',eventRes.body.message);
            }
          }
        }
      }, error => {
        switch(error.status) {
          case 401:
            /***  Auto LogOut if Api response 401 ** */
            this.commonbService.presentsToast('warning','top-end', "You have been loggedOut for security purpose.");
            this.router.navigate([`/taxi/login`]);
            localStorage.clear();
            break;
          case 500:
            /*** If api does not respond  ** */ 
            this.commonbService.presentsToast('warning','top-end','Api Not Working');
            break;

          default:
            if(error.error) {
              this.commonbService.presentsToast('error','top-end',error.error.message);
            } else {
              this.commonbService.presentsToast('error','top-end',error.message);
            }
        }
        
      })
    );
  }
}
