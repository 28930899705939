import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { apiList } from './api-list';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpsService {
  baseUrl: string = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private apiList: apiList
  ) { }

  httpPost(url:string, params): Observable<Object>  {
    return this.http.post(this.baseUrl + this.apiList[url], params);
  }

  httpPostWithHeader(url:string, params: any): Observable<Object>  {
    return this.http.post(this.baseUrl + this.apiList[url] , params, this.header());
  }

  httpGetWithQParam(url:string, params): Observable<Object>  {
    return this.http.get(this.baseUrl + this.apiList[url] +"?"+params, this.header());
  }

  httpGet(url:string): Observable<Object>  {
    return this.http.get(this.baseUrl+ this.apiList[url]);
  }

  httpPostWithHeaderOnly(url:string, ): Observable<Object>  {
    return this.http.get(this.baseUrl + this.apiList[url], this.header());
  }

  fileUpload(fileData: any) {
    var formData = new FormData();
    formData.append('image', fileData, fileData.name);
    const headers = new HttpHeaders({
      "mimeType": "multipart/form-data",
      authorization: localStorage.accessToken
    });
    const headersObj = { headers };
    return this.http.post(this.baseUrl + this.apiList['uploadFile'], formData, headersObj);
  }

  header() {
    if (localStorage.accessToken != undefined || localStorage.accessToken != null) {
      const headers = new HttpHeaders({
        'cache-control': 'no-cache',
        'content-type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // "mimeType": "multipart/form-data",
        authorization: localStorage.accessToken
      });
      const option = {
        headers
      };
      return option;
    }
  }

  userFileUploadWithoutAuth(fileData: any,formKey,apiUrl) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append(formKey, fileData);
      const headers = new HttpHeaders({
        "mimeType": "multipart/form-data",
        // authorization: localStorage.accessToken
      });
      const headersObj = { headers };
      this.http.post(this.baseUrl + this.apiList[apiUrl], formData, headersObj).subscribe((res: any) => {
        if(res.statusCode == 200) {
          if(res.data.length > 0) {
            return resolve(res.data[0]);
          }
        }
      });
    });
  }

  import(fileData: any,formKey,apiUrl) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append(formKey, fileData);
      const headers = new HttpHeaders({
        "mimeType": "multipart/form-data",
        authorization: localStorage.accessToken
      });
      const headersObj = { headers };
      this.http.post(this.baseUrl + this.apiList[apiUrl], formData, headersObj).subscribe((res: any) => {
        if(res.statusCode == 200) {
          return resolve(res.data);
        }
      });
    });
  }

}