import { Component, OnInit } from "@angular/core";
import { Constant } from "src/app/core/constant";
import { HttpsService } from "src/app/services/http/http.service";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"],
})
export class PrivacyComponent implements OnInit {
  privacyPolicy = "";
  constructor(private http: HttpsService, public constant: Constant) {
    this.getAllCms();
  }

  ngOnInit() {}

  getAllCms() {
    this.http.httpPost("getAllCms", {}).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.privacyPolicy = res.data.find(
          (el: any) => el.cmsType === this.constant.CMS.PRIVACY_POLICY
        ).html;
      }
    });
  }
}
