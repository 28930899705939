import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MaterialModule } from "./material/material.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./component/header/header.component";
import { FooterComponent } from "./component/footer/footer.component";
import { HomeComponent } from "./pages/home/home.component";
import { InstructionComponent } from "./pages/instruction/instruction.component";
import { GetstartComponent } from "./pages/getstart/getstart.component";
import { PackagedetailsComponent } from "./pages/packagedetails/packagedetails.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { apiList } from "./services/http/api-list";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SignupComponent } from "./pages/signup/signup.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { Constant } from "./core/constant";
import { HttpInterceptorService } from "./services/interceptor/http-interceptor.service";
import {
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
  NgxUiLoaderService,
} from "ngx-ui-loader";
import { AgmCoreModule, GoogleMapsAPIWrapper, MapsAPILoader } from "@agm/core";
import { PaymentComponent } from "./pages/payment/payment.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "blue",
  // bgsOpacity: 0.5,
  // bgsPosition: POSITION.bottomLeft,
  // bgsSize: 60,
  // bgsType: SPINNER.chasingDots,
  // blur: 5,
  // delay: 0,
  fastFadeOut: true,
  fgsColor: "blue",
  // fgsPosition: POSITION.centerCenter,
  // fgsSize: 60,
  // fgsType: SPINNER.chasingDots,
  // gap: 24,
  // logoPosition: POSITION.centerCenter,
  // logoSize: 120,
  // logoUrl: 'assets/angular.png',
  // overlayBorderRadius: '0',
  // overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: "blue",
  // pbDirection: PB_DIRECTION.leftToRight,
  // pbThickness: 5,
  // hasProgressBar: false,
  // text: 'Welcome to ngx-ui-loader',
  // textColor: '#FFFFFF',
  // textPosition: POSITION.centerCenter,
  // maxTime: -1,
  // minTime: 500
};
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    InstructionComponent,
    GetstartComponent,
    PackagedetailsComponent,
    SignupComponent,
    PaymentComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxUiLoaderModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyByqxn6WSYUNMVPrP052hSjzpwVvy2sUg0",
      libraries: ["drawing", "places", "geometry"],
    }),

    // NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    // NgxUiLoaderHttpModule,
    // NgxUiLoaderHttpModule
  ],
  providers: [
    //  GoogleMapsAPIWrapper,
    NgxUiLoaderService,
    apiList,
    Constant,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
