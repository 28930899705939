import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/core/must-match.validators';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { environment } from 'src/environments/environment';
import { Constant } from 'src/app/core/constant';
import { HttpsService } from 'src/app/services/http/http.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  image: FormControl = new FormControl(null);
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  propertyForm: FormGroup;
  submitted: boolean = false;
  fileData: any = '';
  fileDataUrl: string = '';
  imgBaseUrl = environment.imageUrl;
  
  constructor(private fb: FormBuilder,
    private constant: Constant,
    private https: HttpsService,
    private commonService: CommonService,
    private router: Router) { }

  ngOnInit() {
    document.body.scrollTop = document.body.offsetTop;
    this.propertyForm = this.fb.group({
      image: [''],
      name: ['', Validators.required],
      email: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phoneNo: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.minLength(4)]],
      city: ['', Validators.required],
      state: ['', Validators.required],
    });
    document.querySelector("input").addEventListener("keypress", function (evt) {
      if (evt.which < 48 || evt.which > 57)
      {
          evt.preventDefault();
      }
  });
  }

  submit() {
    this.submitted = true;
    if (this.propertyForm.valid) {
      var codeLength = this.propertyForm.value.phoneNo.dialCode.length;
      var param = $.extend({}, {
        "state": this.propertyForm.value.state,
        "city": this.propertyForm.value.city,
        "zipCode": this.propertyForm.value.zipCode,
        "societyName": this.propertyForm.value.name,
        "email": this.propertyForm.value.email,
        "phoneNo": this.propertyForm.value.phoneNo.e164Number.slice(codeLength),
        "countryCode": this.propertyForm.value.phoneNo.dialCode,
        "deviceType": this.constant.DEVICE_TYPE.WEB,
        "images": this.fileData !== '' ? [this.fileData] : undefined,
      });
      this.https.httpPost('addSociety', param).subscribe((res: any) => {
        if (res.statusCode == 203) {
          this.commonService.presentsToast('success', 'top-end', res.message);
          this.submitted = false;
          this.propertyForm.reset();
          this.fileDataUrl = '';
          this.fileData = '';
          if (localStorage.packageId !== undefined) {
            this.router.navigate(['/packagedetails/' + localStorage.packageId]);
          } else {
            this.router.navigate(['/getstart']);
          }
          // this.router.navigate(['/getstart']);
          localStorage.setItem('societyId', res.data._id);
        } else if (res.statusCode == 200) {
          this.commonService.presentsToast('error', 'top-end', res.message);
        }
      });
    }
  }

  onFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.https.userFileUploadWithoutAuth(fileInput.target.files[0], 'society', 'upload').then((res: any) => {
        this.fileDataUrl = this.imgBaseUrl + res.filePath;
        this.fileData = res;
      });
    }
  }

  onkeydown(event) {
    // var ch = String.fromCharCode(event.keyCode);
    // var filter = /[a-zA-Z]/;
    // // if (!filter.test(ch)) {
    // //   event.returnValue = false;
    // // }
    // if ((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || event.keyCode == 8)
    //   return true;
    // else {
    //   event.returnValue = false;
    //   return false;
    // }
    if(event.charCode !== 32) {
      return (event.charCode > 64 && 
        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)
    }
  }

}
