import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { CommonService } from 'src/app/services/common/common.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  linkArr = [
    { name: 'Home', isActive: false, route: 'home' },
    { name: 'Instruction', isActive: false, route: 'instruction' },
    { name: 'Sign Up', isActive: false, route: 'getstart' },
    { name: 'Contact Us', isActive: false, route: '' },
  ];

  constructor(private router: Router,
    private commonService: CommonService) { }

  ngOnInit() {
    this.navChange();
  }

  navChange() {
    // this.linkArr.forEach(element => {
    //   element.isActive = false;
    // });
    // console.log(this.router.url)
    // data.isActive = true;
    this.linkArr.forEach((data: any) => {
      if(this.router.url.search(data.route) === 1) {
        data.isActive = true;
      }
    });
  }

  goToRoute(data) {
    // this.linkArr.forEach(element => {
    //     element.isActive = false;
    //   });
    // data.isActive = true;
    console.log(data)
    if(data.name === "Contact Us") {
      this.commonService.scrollToContactUs.next(true);
    } else {
      this.router.navigateByUrl(data.route);
    }
  }

  goToSubAdmin() {
    window.open('https://fincogrp.com/admin/','_blank');
  }

  goTogetstart() {
    this.router.navigate(['/getstart']);
  }
  goToinstruction() {
    this.router.navigate(['/instruction']);
  }
  goTohome() {
    this.router.navigate(['/home']);
  }
}


