import { AfterViewInit, Component, NgZone, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "src/app/services/common/common.service";
import { HttpsService } from "src/app/services/http/http.service";
import { MapsAPILoader } from "@agm/core";
declare var google: any;
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, AfterViewInit {
  collabForm: FormGroup;
  submitted: boolean = false;
  currentYear: number;
  map: google.maps.Map;

  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private https: HttpsService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    var d = new Date();
    this.currentYear = d.getFullYear();
    this.collabForm = this.fb.group({
      name: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      phone: ["", [Validators.required, Validators.minLength(7)]],
      subject: ["", Validators.required],
      msg: ["", Validators.required],
    });
    this.commonService.getScrollToContactUs.subscribe((res: boolean) => {
      if (res) {
        document.getElementById("footer_view").scrollIntoView();
        this.commonService.scrollToContactUs.next(false);
      }
    });
  }

  ngAfterViewInit() {
    this.mapsAPILoader.load().then(() => {
      const map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 30.3822448, lng: -86.3919797 },
        zoom: 12,
        streetViewControl: false,
        styles: [
          { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
          { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
          { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#f5f5f5" }],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [{ color: "#bdbdbd" }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#eeeeee" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#757575" }],
          },
          { featureType: "poi.business", stylers: [{ visibility: "off" }] },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#e5e5e5" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [{ color: "#757575" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#dadada" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#616161" }],
          },
          {
            featureType: "road.local",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [{ color: "#e5e5e5" }],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [{ color: "#eeeeee" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#c9c9c9" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
        ],
      });
    });
  }

  initMap() {
    console.log("footer loaded");
    // Styles a map in night mode.
    const map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 30.3822448, lng: -86.3919797 },
      zoom: 12,
      styles: [
        { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
        { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [{ color: "#bdbdbd" }],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#eeeeee" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#757575" }],
        },
        { featureType: "poi.business", stylers: [{ visibility: "off" }] },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#e5e5e5" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [{ color: "#757575" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#dadada" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#616161" }],
        },
        {
          featureType: "road.local",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [{ color: "#e5e5e5" }],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [{ color: "#eeeeee" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#c9c9c9" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
      ],
    });
  }

  submit() {
    this.submitted = true;
    if (this.collabForm.valid) {
      var param = {
        name: this.collabForm.value.name.trim(),
        email: this.collabForm.value.email.trim().toLowerCase(),
        message: this.collabForm.value.msg.trim(),
        phone: this.collabForm.value.phone.trim(),
        subject: this.collabForm.value.subject.trim(),
      };
      this.https
        .httpPostWithHeader("addCollaboration", param)
        .subscribe((res: any) => {
          if (res.statusCode == 203 || res.statusCode == 200) {
            this.collabForm.reset();
            this.submitted = false;
            this.commonService.presentsToast("success", "top-end", res.message);
          }
        });
    }
  }

  keypresshandler(event) {
    var charCode = event.keyCode;
    //Non-numeric character range
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  }

  goToSubAdmin() {
    window.open("https://fincogrp.com/admin/", "_blank");
  }

  goToPrivacy() {
    if (this.router.url.search("privacy") !== -1) {
      document.getElementById("top").scrollIntoView();
    } else {
      this.router.navigateByUrl("/privacy#top");
    }
  }

  goToInstruction() {
    if (this.router.url.search("instruction") !== -1) {
      document.getElementById("top").scrollIntoView();
    } else {
      this.router.navigateByUrl("/instruction#top");
    }
  }
  goToSignup() {
    if (this.router.url.search("getstart") !== -1) {
      document.getElementById("top").scrollIntoView();
    } else {
      this.router.navigateByUrl("/getstart#top");
    }
  }
  goToHome() {
    if (this.router.url.search("home") !== -1) {
      document.getElementById("top").scrollIntoView();
    } else {
      this.router.navigateByUrl("/home#top");
    }
  }
}
